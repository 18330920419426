import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import { activateEmail } from '../../actions/userActions';
import LoginLayout from '../../layout/LoginLayout';
import { FUserTypeEnum, userTypeKnownAction } from '../../reducers/userReducer';
import debug from '../../utils/debug';
import RegisterView, { ActiveStep } from '../../views/register/Index';

const isBrowser = typeof window !== 'undefined';

const RegisterPage = (): React.ReactElement => {
  debug('Register page is loaded at', isBrowser && window.location);
  let email: string | string[] | undefined | null;
  let secret: string | string[] | undefined | null;
  let auth: string | string[] | undefined | null;
  let userType: string | string[] | undefined | null;
  const originUrl = isBrowser ? window.location.href : '';
  const [activeStep, setActiveStep] = useState<ActiveStep>(ActiveStep.EMAIL_PASSWORD_INPUT);
  const dispatch = useDispatch();
  const handleActivateEmail = async (): Promise<void> => {
    setActiveStep(ActiveStep.EMAIL_VERIFY);
    await dispatch(activateEmail(email, secret, auth, userType, originUrl));
    setActiveStep(ActiveStep.EMAIL_SENT);
  };

  if (isBrowser && location && location.search) {
    ({ email, secret, auth, userType } = queryString.parse(location.search));
    debug('Activate email at', originUrl);
    switch (userType) {
      case FUserTypeEnum.ADMIN:
        dispatch(userTypeKnownAction(FUserTypeEnum.ADMIN));
        break;
      case FUserTypeEnum.COMPANY:
        dispatch(userTypeKnownAction(FUserTypeEnum.COMPANY));
        break;
      case FUserTypeEnum.CONSULTANT:
        dispatch(userTypeKnownAction(FUserTypeEnum.CONSULTANT));
        break;
      default:
        dispatch(
          userTypeKnownAction(
            (process.env.GATSBY_ACTIVE_APP as FUserTypeEnum) || FUserTypeEnum.COMPANY
          )
        );
    }
  }

  useEffect(() => {
    if (email && secret && auth) {
      handleActivateEmail();
      return;
    }
  }, []);

  return (
    <LoginLayout>
      <Helmet title="顾问注册"></Helmet>
      <RegisterView
        userType={FUserTypeEnum.CONSULTANT}
        inputEmail={email as string | undefined}
        activeStatus={activeStep}
        originUrl={originUrl}
      />
    </LoginLayout>
  );
};

export default RegisterPage;
